#root {
  .ant-input {
    background: #F7F7F8;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    height: 50px;
  }

  .ant-input-password {
    border: 0;
    padding: 0;

    input {
      background: #F7F7F8;
      padding: 10px;
      direction: ltr;
    }

    .ant-input-suffix {
      position: absolute;
      height: 100%;
      right: 15px;
    }
  }

  .ant-btn:not(.ant-btn-circle) {
    border-radius: 10px;

    &.ant-btn-lg {
      height: 50px;
    }
  }

  .ant-input {
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper {
    border: 0;
    direction: ltr;
    padding: 0;
    box-shadow: none !important;

    .ant-input-prefix {
      margin: 0;
      margin-right: 5px;
    }

    input {
      padding: 0 8px;
    }
  }

  .ant-menu-item, .ant-menu-submenu {
    opacity: .6;

    &:hover, &.ant-menu-item-selected, &.ant-menu-submenu-open {
      opacity: .9;
      background: linear-gradient(-90deg, #aca9ff3d 15%, rgba(172, 169, 255, 0) 85%);
    }
  }

  svg.ant-menu-item-icon {
    display: inline-block;
    vertical-align: text-bottom;
  }

  .ant-menu {
    border-left: none;

    &.ant-menu-inline-collapsed {
      max-width: 80px;

      & > .ant-menu-item {
        padding: 0 calc(50% - 12px);
      }

      &.ant-menu-vertical .ant-menu-submenu-title {
        padding: 0 calc(50% - 12px);
      }
    }
  }

  .ant-layout-sider-trigger {
    border-top: 1px solid #d5d5d5;
    opacity: .6;
    transform: scaleX(-1);
  }

  .scale-reverse {
    transform: scaleX(-1);
  }

  .featured .ant-table-cell.ant-table-cell-row-hover {
    background: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }
}