@tailwind components;
@tailwind utilities;
@import '../font/font.scss';
@import 'antd.scss';

body {
  margin: 0;
  height: auto !important;
}

* {
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#root {
  height: 100%;

  .dir-ltr {
    direction: ltr;
  }
}

.nprogress-custom-parent #nprogress .spinner {
  right: auto;
  left: 15px;
}